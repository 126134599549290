import {XCircleIcon} from "@heroicons/react/20/solid";
import React from "react";
import {uuid4} from "@sentry/utils";

export default function ErrorAlert({open, message}: Readonly<{ open: boolean, message: string[] }>) {
  if (!open) {
    return (<></>)
  }

  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true"/>
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">There were errors</h3>
          <div className="mt-2 text-sm text-red-700">
            <ul className="list-disc space-y-1 pl-5">
              {message.map((m) => (<li key={uuid4()}>{m}</li>))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
