"use client"

import Link from 'next/link'

import {Button} from '@/components/button'
import {TextField} from '@/components/fields'
import {CheckIcon, XCircleIcon} from '@heroicons/react/20/solid'
import React, {Fragment} from "react";
import clsx from "clsx";
import {Dialog, Transition} from "@headlessui/react";
import {ExclamationTriangleIcon, XMarkIcon} from "@heroicons/react/24/outline";
import ErrorAlert from "@/components/error-alert";
import ButtonSpinner from "@/components/button-spinner";
import {closeSnackbar, enqueueSnackbar, SnackbarProvider} from "notistack";
import Turnstile, {useTurnstile} from "react-turnstile";

function Alert({className, text}: Readonly<{ className?: string, text: React.ReactNode }>) {
  return (
    <div className={clsx("rounded-md bg-red-50 p-4", className)}>
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true"/>
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            {text}
          </h3>
        </div>
      </div>
    </div>
  )
}

function Success({className}: Readonly<{ className?: string }>) {
  return (
    <div className={clsx("rounded-md bg-green-50 p-4 m-4", className)}>
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckIcon className="h-5 w-5 text-green-400" aria-hidden="true"/>
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium">
            Password reset email sent. Please check your inbox.
          </h3>
        </div>
      </div>
    </div>
  )
}

function EmailVerified({className}: Readonly<{ className?: string }>) {
  return (
    <div className={clsx("rounded-md bg-green-50 p-4 m-4", className)}>
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckIcon className="h-5 w-5 text-green-400" aria-hidden="true"/>
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium">
            Email verified. You can now log in.
          </h3>
        </div>
      </div>
    </div>
  )
}

function PasswordResetModal({modalOpen, setModalOpen}: Readonly<{ modalOpen: boolean, setModalOpen: (open: boolean) => void }>) {
  const [inProgress, setInProgress] = React.useState(false)
  const [alertVisible, setAlertVisible] = React.useState(false)
  const [alertTxt, setAlertTxt] = React.useState<string>("An error has occured. Please try again.")
  const [successVisible, setSuccessVisible] = React.useState(false)

  const turnstile = useTurnstile();

  async function passwordReset(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()

    setAlertVisible(false)
    setInProgress(true)
    setSuccessVisible(false)

    const turnstile_response = turnstile.getResponse()
    if (!turnstile_response) {
      setAlertTxt("Please complete the human verification.")
      setAlertVisible(true)
      setInProgress(false)
      return
    }

    try {
      const response = await fetch(`/api/reset-password`, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          email: (event.currentTarget.elements.namedItem('email') as HTMLInputElement).value,
          turnstile_response,
        })
      })

      if (!response.ok) {
        turnstile.reset()
        setInProgress(false)

        if (response.status === 400) {
          response.json().then((data: { condition: string, field: "email" | "turnstile_response" }[]) => {
            const errorText = data.map(({condition, field}) => {
              const fieldTranslate = {
                email: 'Email address',
                turnstile_response: 'Human verification',
              }

              if (condition === 'required') {
                return `${fieldTranslate[field]} is required.`
              } else if (condition === 'email') {
                return `${fieldTranslate[field]} field must be a valid email address.`
              } else {
                return "An error has occured. Please try again."
              }
            })

            setAlertTxt(errorText.join(" "))
          })
        } else {
          setAlertTxt("An error has occured. Please try again.")
        }

        setAlertVisible(true)
        return
      }

    } catch
      (e) {
      setInProgress(false)
      setAlertVisible(true)
      return
    }

    setInProgress(false)
    setSuccessVisible(true)
  }

  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setModalOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setModalOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                  </button>
                </div>
                <form onSubmit={passwordReset}>
                  <div className="sm:flex sm:items-start">
                    <div
                      className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true"/>
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Reset your password
                      </Dialog.Title>
                      <div className="mt-2">
                        <ErrorAlert open={alertVisible} message={[alertTxt]}/>
                        {successVisible && <Success/>}
                        <div>
                          <TextField
                            id="email"
                            label="Email address"
                            name="email"
                            type="email"
                            autoComplete="email"
                            onFocus={() => setAlertVisible(false)}
                            required
                          />
                        </div>

                      </div>
                      <Turnstile sitekey={process.env.NEXT_PUBLIC_TURNSTILE_KEY ?? ""} className="my-5"/>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    >
                      <ButtonSpinner open={inProgress}/>
                      <span>Submit</span>
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setModalOpen(false)}
                    >
                      Dismiss
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default function LoginPage({searchParams: {redirect, email_verified}}: Readonly<{ searchParams: { redirect: string, email_verified: string } }>) {
  const [alertVisible, setAlertVisible] = React.useState(false)
  const [alertText, setAlertText] = React.useState<React.ReactNode>("An error has occured. Please try again.")
  const [loading, setLoading] = React.useState(false)
  const [resetModalOpen, setResetModalOpen] = React.useState(false)

  const turnstile = useTurnstile();

  function sendVerificationEmail() {
    const inprogressKey = enqueueSnackbar('Sending verification email ...', {variant: 'info', persist: true})

    fetch(`/api/public/user/send-verification-email`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
      body: JSON.stringify({email: (document.getElementById('email') as HTMLInputElement).value}),
    }).then((res) => {
      closeSnackbar(inprogressKey)

      if (res.ok) {
        enqueueSnackbar('Verification email sent.', {variant: 'success'})
      } else if (res.status === 409) {
        enqueueSnackbar('Email address already verified.', {variant: 'info'})
      } else if (res.status === 429) {
        enqueueSnackbar('Verification email sent already. Please try again in 5 minutes.', {variant: 'error'})
      } else {
        enqueueSnackbar('An error has occured. Please try again.', {variant: 'error'})
      }
    })
  }

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    setAlertVisible(false)

    if (turnstile.isExpired()) {
      setAlertText("Cloudflare validation expired, please try again or reload the page")
      setAlertVisible(true)
      return
    }

    const turnstile_response = turnstile.getResponse()
    if (!turnstile_response) {
      setAlertText("Please complete the human verification.")
      setAlertVisible(true)
      return
    }

    setLoading(true)

    const inprogressKey = enqueueSnackbar('Logging in...', {variant: 'info', persist: true})

    const formData = new FormData(event.currentTarget)
    const email = formData.get('email')
    const password = formData.get('password')

    const response = await fetch(
      `/api/login`,
      {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({email, password, turnstile_response}),
      },
    )

    closeSnackbar(inprogressKey)

    if (!response.ok) {
      turnstile.reset()

      if (response.status === 400) {
        response.json().then((data: { condition: string, field: "email" | "password" | "turnstile_response" }[]) => {
          data.map(({condition, field}) => {
            const fieldTranslate = {
              email: 'Email address',
              password: 'Password',
              turnstile_response: 'Human verification',
            }

            if (condition === 'required') {
              setAlertText(`${fieldTranslate[field]} is required.`)
            } else if (condition === 'email') {
              setAlertText(`${fieldTranslate[field]} field must be a valid email address.`)
            } else {
              setAlertText("An error has occured. Please try again.")
            }
          })
        })
      } else if (response.status === 401) {
        setAlertText("Invalid email or password.")
      } else if (response.status === 425) {
        setAlertText(<p>Your email address not verified yet. Please <a onClick={sendVerificationEmail} className="text-blue-600 hover:underline cursor-pointer">click here to send</a> a new verification email.</p>)
      } else {
        setAlertText("An error has occured. Please try again.")
      }

      setLoading(false)
      setAlertVisible(true)
      return
    }

    if (redirect) {
      window.location.href = Buffer.from(redirect, 'base64').toString('ascii')
    } else {
      window.location.href = "/dashboard"
    }
    setLoading(false)
  }

  return (
    <>
      <SnackbarProvider/>
      <PasswordResetModal modalOpen={resetModalOpen} setModalOpen={setResetModalOpen}/>
      <div className="relative isolate bg-white">
        <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
          <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
              <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-1 ring-gray-900/10 lg:w-1/2">
                <svg
                  className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                      width={200}
                      height={200}
                      x="100%"
                      y={-1}
                      patternUnits="userSpaceOnUse"
                    >
                      <path d="M130 200V.5M.5 .5H200" fill="none"/>
                    </pattern>
                  </defs>
                  <rect width="100%" height="100%" strokeWidth={0} fill="white"/>
                  <svg x="100%" y={-1} className="overflow-visible fill-gray-50">
                    <path d="M-470.5 0h201v201h-201Z" strokeWidth={0}/>
                  </svg>
                  <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"/>
                </svg>
              </div>
              <h2 className="text-3xl font-bold tracking-tight text-gray-900">Sign in to your account</h2>
              <div className="mt-6 text-lg leading-8 text-gray-600">
                Don’t have an account?{' '}
                <Link href={"/register" + (redirect ? `?redirect=${redirect}` : '')} className="font-medium text-blue-600 hover:underline">
                  Sign up
                </Link>{' '}
                now and get 14 days money back guarantee.
              </div>
            </div>
          </div>

          <div className="px-6 pb-24 lg:pt-20 sm:pb-32 lg:px-8">
            <Alert className={clsx("mt-2", !alertVisible && "hidden")} text={alertText}/>
            {email_verified === "true" && <EmailVerified/>}
            <form className="mt-10 grid grid-cols-1 gap-y-8" onSubmit={onSubmit}>
              <TextField
                tabIndex={0}
                id="email"
                label="Email address"
                name="email"
                type="email"
                autoComplete="email"
                onFocus={() => setAlertVisible(false)}
                required
                autoFocus
              />
              <TextField
                tabIndex={0}
                id="password"
                label="Password"
                name="password"
                type="password"
                autoComplete="current-password"
                onFocus={() => setAlertVisible(false)}
                required
              />
              <div className="font-light text-sm">
                Forgot your password?{' '}
                <a role="button" onClick={() => setResetModalOpen(true)} className="font-medium text-blue-600 hover:underline cursor-pointer">Reset it!</a>
              </div>
              <Turnstile sitekey={process.env.NEXT_PUBLIC_TURNSTILE_KEY ?? ""}/>
              <div>
                <Button tabIndex={0} type="submit" variant="solid" color="blue" className="w-full">
                  <svg className={clsx("mr-3 h-5 w-5 animate-spin text-white", !loading && "invisible")} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  <span>Login <span aria-hidden="true">&rarr;</span></span>
                </Button>
              </div>
            </form>
          </div>

        </div>
      </div>
    </>
  )
}
